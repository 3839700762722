.title-text {
    font-size: 35px;
    line-height: 1.2em;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}

.description-text {
    font-size: 17px !important;
    font-family: 'Basier Square', sans-serif;
    letter-spacing: 0;
    color: #7f7f7f;
    line-height: 1.85em !important;
}

.font {
    font-family: 'Basier Square', sans-serif;
    font-size: 1em;
    line-height: 1.5em;
    letter-spacing: 0;
}

.height {
    height: 100%;
}

.labs {
    font-family: Special Elite;
    color: #e94333;
}

.atto {
    margin-top: 20px;
    font-family: 'Germania One', cursive;
    font-weight: 400;
    font-size: 26px;
    color: white;
}

.icons-color {
    color: #5d64f1 !important;
}

.mt-local {
    margin-top: 6.5rem;
}

#__react-alert__ div div div {
    width: auto !important;
    font-size: 12px !important;
    z-index: 9999;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}