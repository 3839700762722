.menu-privacy {
    position: fixed;
}

.menu-privacy .line-privacy {
    position: absolute;
    background-color: rgb(138, 138, 138);
    border-radius: 5px;
    max-width: 4px;
}

.menu-privacy a {
    padding-left: 20px;
    padding-bottom: 15px;
    cursor: pointer;
    width: 150px;
}

.menu-privacy a.active {
    color: #595959;
    transition: 0.7ms all;
    border-left: 4px solid #22203E;
    padding-left: -10px;
}