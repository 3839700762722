.country-option{
    width: 30px;
}
.btn-tranlate {
    background: transparent;
    border: none;
    color: white;
}

.img-translate {
    margin-right: 6px;
    width: 18px;
    height: 17px;
}

.tanslate-navbar {
    padding-left: 20px;
    /* position: absolute; */
    /* top: 10px; */
    /* left: 20%; */
}

.custom-select__control {
    position: relative !important;
    background: transparent !important;
    border: none !important;
    border-radius: 0 !important;
    box-shadow: none !important;
}

.custom-select__indicator-separator {
    display: none !important;
}

.custom-select__indicator {
    padding-left: 0px !important;
}

.custom-select__value-container {
    padding-right: 3px !important;
}

.custom-select__single-value {
    color: white !important;
}
.custom-select__dropdown-indicator{
    display: none !important;
}


.header {
    position: fixed;
    height: 70px;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    transition: .3s ease-in;
    overflow: hidden;
    background-color: transparent;
    margin: 0;
    padding: 0;
}

.header.active {
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.25);
    background-color: #22203e;
}

.header .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0;
}

.header .navbar a {
    margin: 0;
    padding: 0;
    height: 50px;
}

.header .navbar .logo-img {
    height: 60px;
    margin-right: 5px;
}

.header .navbar p {
    padding-top: 20px;
    font-family: 'Germania One', cursive;
    font-weight: 400;
    font-size: 26px;
    color: white;
}

.header .navbar p span {
    color: #E94333;
    margin-left: 2px;
}


.header .nav-menu a {
    color: white;
    transition: .3s ease-out;
}

.header .navbar img {
    height: auto;
}

.header .nav-menu {
    display: flex;
    align-items: center;

}

.header .nav-item {
    padding: 0rem 0rem 0rem 4rem;
    font-weight: 400;
}

.nav-menu a.active {
    padding-bottom: 5px;
    border-bottom: 2px solid #E94333;
}

.header .nav-item a:hover {
    color: white;
    padding-bottom: 5px;
    border-bottom: 2px solid #E94333;
    cursor: pointer;
}

.hamburger {
    display: none;
}

.header ul {
    list-style-type: none;
    margin: 0;
}

a {
    text-decoration: none;
}



@media screen and (max-width:1140px) {
    .header {
        max-width: 100%;
    }

    .header .navbar {
        max-width: 100%;
        margin-left: 10px;
        margin-right: 10px;
    }

    .hamburger {
        display: block;
    }

    .nav-menu {
        position: fixed;
        left: -100%;
        top: 70px;
        flex-direction: column;
        background-color: #22203e;
        width: 100%;
        height: 90vh;
        z-index: 999;
        text-align: center;
    }

    .nav-menu.active {
        position: fixed;
        left: 0;
        z-index: 999;
    }

    .header .nav-item {
        padding-left: 0px;
    }

    .nav-item {
        padding: 2rem;
        margin: 1rem 0;
    }
}

@media (max-width: 500px) {
    .nav-menu {
        padding: 0;
    }

    .header .nav-item {
        padding: 1rem;
    }
    .tanslate-navbar {
        padding-left: 0px;
    }
}