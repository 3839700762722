
.section-use-tech {
  width: 100%;
  padding-bottom: 100px;
}


::-webkit-scrollbar {
  display: none;
}

@keyframes scrollusetech {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(-100%, 0);
  }
}

.scroll_bg {
  display: block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.section-use-tech .img {
  display: inline-block;
  -webkit-animation: scrollusetech 40s infinite linear;
  -moz-animation: scrollusetech 40s infinite linear;
  animation: scrollusetech 40s infinite linear;
  width: auto;
  margin-left: 5px;
}

.section-use-tech  .d-flex {
  gap: 100px;
}

@media (max-width: 500px) {
  .section-use-tech  .d-flex {
    gap: 50px;
  }
}

