.pagination-vacvancy .form-select {
    width: 6%;
    height: 34px;
    padding-left: 17px;
    padding-top: 5px;
    margin-top: -2px;
}

.post-vacancy-section {
    margin-top: 100px;
}

.select-vacancy {
    margin-left: 80px;
    margin-bottom: 100px;
}

.all-vacancy {
    background: #EAEEFF;
}

.input-group-text {
    background: transparent;
    border-left: 0;
}

.input-group {
    /* width: 300px; */
}

.selectpicker {
    width: 300px;
    height: 50px;
}

.form-select {
    height: 50px;
}

.search-section {
    margin-bottom: 20px;
}

.card-all-vacancy {
    max-width: 420px;
    background: #FFFFFF;
    border-radius: 15px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    transition: 0.6s;
    cursor: pointer;
}

.card-all-vacancy .content-all-vacancy {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: 0.8s;
    overflow-y: auto;
}

.card-all-vacancy.active .content-all-vacancy {
    height: auto;
}

.card-all-vacancy:hover {
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    transform: scale(1.03);
}

.list-card-all-vacancy {
    line-height: 150%;
    font-weight: 600;
    padding: 8px;
}

.list-card-all-vacancy:hover {
    background: #EAEEFF;
    border-radius: 10px;
    transform: scale(1.03);
}

.li {
    max-height: 0px;
    transition: max-height 0.35s ease-out;
}

.ul {
    overflow: hidden;
}

.ul.opened .li {
    max-height: 50px;
    transition: max-height 0.35s ease-in;
}


.card-select-vacancy {
    margin: 50px 20px 0px 0px;
    background: #FFFFFF;
    border-radius: 15px;
    border-right: 1px solid rgba(45, 85, 255, 0.13);
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.1);
    transition: 0.6s;
    cursor: pointer;
}

.card-select-vacancy:hover {
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    transform: scale(1.01);
}

.type-vacancy {
    background: #EBEFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    transition: 0.7s;
    padding: 10px;
    width: 115px;
}

.type-vacancy p {
    font-size: 12px;
    margin-bottom: 0px;
    text-transform: uppercase;
    background: radial-gradient(107.25% 875.51% at 100% 85%, #A74AE7 0%, #0085FF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.location:hover {
    cursor: pointer;
    transform: scale(1.1);
}

/* .type-vacancy:hover {
    cursor: pointer;
    transform: scale(1.1);
} */

.location {
    background: #EBEFFF;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    flex-flow: row;
    padding: 10px;
    transition: 0.7s;
}

.location i {
    color: #2D55FF;
}

.location p {
    font-size: 12px;
    margin-bottom: 0px;
    text-transform: uppercase;
    color: #2D55FF;
    margin-left: 10px;
}

.vacancy-description {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
}


.all-vacancy-section {
    height: 25vh;
    background: url(../../../assets/images/vacancy/back-vacancy.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.btn-search {
    border: none;
    outline: none;
    position: relative;
    width: 50px;
    height: 50px;
    font-weight: 500;
    font-size: 18px;
    color: #FFFFFF;
    cursor: pointer;
    background: radial-gradient(107.25% 875.51% at 100% 85%, #A74AE7 0%, #0085FF 100%);
    border-radius: 10px;
    border: none;
    transition: 0.8s;
    text-align: center;
    color: white;
    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
    border: none;
}

.btn-open-vacancy {
    border: none;
    outline: none;
    position: relative;
    width: 200px;
    height: 60px;
    font-weight: 500;
    font-size: 18px;
    color: #FFFFFF;
    cursor: pointer;
    background: radial-gradient(107.25% 875.51% at 100% 85%, #A74AE7 0%, #0085FF 100%);
    border-radius: 40px;
    transition: 0.8s;
    text-align: center;
    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
    border: none;
    letter-spacing: 4px;
}

.btn-open-vacancy p {
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-open-vacancy:hover>p>.to-hover {
    display: none;
}

.btn-open-vacancy:hover {
    background: linear-gradient(#fff, #fff) padding-box,
        linear-gradient(45deg, #A74AE7, #0085FF) border-box;
    border: 2px solid transparent;
    border-radius: 40px;
    color: black;
}

.btn-open-vacancy:hover>p {
    background: radial-gradient(107.25% 875.51% at 100% 85%, #A74AE7 0%, #0085FF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.btn-open-vacancy:disabled {
    background: transparent;
    color: #2D55FF;
    border: 1px solid #2D55FF;
}

.all-vacancy-line-max {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -moz-box;
    -moz-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    line-clamp: 5;
    box-orient: vertical;
}

.share-link-tooltip {
    transition: 0.7s;
}

.content-share-button {
    position: relative;
}

.share-link-tooltip .tooltip-share-button {
    visibility: hidden;
    position: absolute;
    border: 1px solid #ced4da;
    border-radius: 5px;
    padding: 10px;
    top: 80px;
    transition: 0.3s;
}


.share-link-tooltip:hover .tooltip-share-button {
    visibility: visible;
    top: 40px;
}
.copy-link-button {
    background: linear-gradient(250deg, #A74AE7, #0085FF);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    padding: 3px;
    width: 32px;
    height: 32px;
}

@media (max-width: 500px) {
    .pagination-vacvancy .form-select {
        width: 25%;
    }
}

@media (max-width: 1200px) {
    .search-section {
        margin-bottom: 40px;
    }

    .btn-open-vacancy {
        width: 150px;
        letter-spacing: 2px;
        height: 50px;
    }
}

.loader-vacancy .spinner {
    margin: auto;
    height: 30vh;
    background: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative
}

.loader-vacancy .spinner .circle {
    animation: spinners linear infinite;
    position: absolute;
    border: 5px solid transparent;
    border-top-color: #F00;
    border-radius: 50%
}

.loader-vacancy .spinner .one {
    width: 50px;
    height: 50px;
    border-top-color: #8f3b76;
    animation-duration: .55s
}

.loader-vacancy .spinner .two {
    width: 70px;
    height: 70px;
    border-top-color: #c7417b;
    animation-duration: .65s
}

.loader-vacancy .spinner .three {
    width: 100px;
    height: 100px;
    border-top-color: #f5487f;
    animation-duration: 1.05s
}


@keyframes spinners {
    0% {
        transform: rotate(0deg)
    }

    100% {
        transform: rotate(360deg)
    }
}

.cursor-pointer {
    cursor: pointer;
}