.about-section {
    margin-top: 130px;
    margin-bottom: 50px;
}

.about-section .about-us {
    font-size: 48px;
    font-weight: 700;
    font-style: normal;
}

.about-content {
    margin-left: 80px;
    margin-right: 80px;
}

.about-section .company-text {
    padding: 10px;
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
}

.btn-about {
    margin-top: 34px;
    border: none;
    outline: none;
    position: relative;
    width: 150px;
    height: 50px;
    font-weight: 500;
    color: #FFFFFF;
    cursor: pointer;
    background: radial-gradient(107.25% 875.51% at 100% 85%, #A74AE7 0%, #0085FF 100%);
    border-radius: 40px;
    font-size: 11px;
    line-height: 14px;
    letter-spacing: 2px;
}

.btn-about p {
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-about:hover>p>.to-hover {
    display: none;
}

.btn-about:hover {
    background: linear-gradient(#fff, #fff) padding-box,
        linear-gradient(45deg, #A74AE7, #0085FF) border-box;
    border: 2px solid transparent;
    border-radius: 40px;
    color: black;
}

.btn-about:hover>p {
    background: radial-gradient(107.25% 875.51% at 100% 85%, #A74AE7 0%, #0085FF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    /* text-fill-color: transparent; */
}


@media screen and (max-width:1140px) {
    .about-content {
        margin-left: 10px;
        margin-right: 10px;
    }

    .about-section {
        margin-top: 100px;
        margin-bottom: 100px;
    }
}

@media (max-width: 500px) {
    .about-section {
        margin-top: 50px;
        margin-bottom: 0px;
    }
}