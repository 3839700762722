/*Vertical animated text rotation*/

a:hover, a:active {
    text-decoration: none;
}

.home {
    width: 100%;
}

.home:before {
    content: ' ';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.9);
}

.rotate {
    width: 45px;
    margin-left: 5px;
    position: -webkit-sticky;
}

.stage {
    width: auto;
    height: auto;
    /* z-index: 1; */
    position: relative;
}

.cubespinner {
    -webkit-animation-name: spincube;
    -webkit-animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-duration: 3s;
    animation-name: spincube;

    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 8s;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform-origin: 12px 12px 0;
    -moz-transform-origin: 12px 12px 0;
    -ms-transform-origin: 12px 12px 0;
    transform-origin: 12px 12px 0;
}

.cubespinner div {
    position: absolute;
    height: 24px;
    text-align: left;
}

.cubespinner .face1 {
    -webkit-transform: translateZ(12px);
    -moz-transform: translateZ(12px);
    -ms-transform: translateZ(12px);
    transform: translateZ(12px);
    -webkit-backface-visibility: hidden;
}

.cubespinner .face2 {
    -webkit-transform: rotateX(120deg) translateZ(12px);
    -moz-transform: rotateX(120deg) translateZ(12px);
    -ms-transform: rotateX(120deg) translateZ(12px);
    transform: rotateX(120deg) translateZ(12px);
    -webkit-backface-visibility: hidden;
}

.cubespinner .face3 {
    -webkit-transform: rotateX(240deg) translateZ(12px);
    -moz-transform: rotateX(240deg) translateZ(12px);
    -ms-transform: rotateX(240deg) translateZ(12px);
    transform: rotateX(240deg) translateZ(12px);
    -webkit-backface-visibility: hidden;
}



/*Cubical Flipping or rotation*/
@-webkit-keyframes spincube {
    from, to {
        -webkit-transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    }
    15% {
        -webkit-transform: rotateX(120deg);
    }
    25% {
        -webkit-transform: rotateX(120deg);
    }
    40% {
        -webkit-transform: rotateX(240deg);
    }
    50% {
        -webkit-transform: rotateX(240deg);
    }
    65% {
        -webkit-transform: rotateX(270deg);
    }
    75% {
        -webkit-transform: rotateX(270deg);
    }
    95% {
        -webkit-transform: rotateX(360deg);
    }
    100% {
        -webkit-transform: rotateX(360deg);
    }
}

@keyframes spincube {
    from, to {
        -webkit-transform: rotateX(0deg);
        -moz-transform: rotateX(0deg);
        -ms-transform: rotateX(0deg);
        transform: rotateX(0deg);
    }
    16% {
        -webkit-transform: rotateX(120deg);
        -moz-transform: rotateX(120deg);
        -ms-transform: rotateX(120deg);
        transform: rotateX(120deg);
    }
    33% {
        -webkit-transform: rotateX(120deg);
        -moz-transform: rotateX(120deg);
        -ms-transform: rotateX(120deg);
        transform: rotateX(120deg);
    }
    50% {
        -webkit-transform: rotateX(240deg);
        -moz-transform: rotateX(240deg);
        -ms-transform: rotateX(240deg);
        transform: rotateX(240deg);
    }
    67% {
        -webkit-transform: rotateX(240deg);
        -moz-transform: rotateX(240deg);
        -ms-transform: rotateX(240deg);
        transform: rotateX(240deg);
    }
    84% {
        -webkit-transform: rotateX(360deg);
        -moz-transform: rotateX(360deg);
        -ms-transform: rotateX(360deg);
        transform: rotateX(360deg);
    }
    100% {
        -webkit-transform: rotateX(360deg);
        -moz-transform: rotateX(360deg);
        -ms-transform: rotateX(360deg);
        transform: rotateX(360deg);
    }
}