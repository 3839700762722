.about-detail-info {
    margin-top: 100px;
    margin-bottom: 80px;
}

.vacancy-section_1 {
    height: 35vh;
    background: url(../../../assets/images/vacancy/back-vacancy.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.card-mission {
    position: relative;
    width: 400px;
    height: auto;
    border: 1px solid #F2F1F1;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    transition: all 0.7s ease-in-out;
}

.img-mission {
    transition: all 0.7s ease-in-out;
    position: absolute;
    top: -35px;
    left: 30px;
}

.img-mission:hover {
    cursor: pointer;
    transition: 0.7s;
    transform: scale(1.1);
}

.content-mission {
    /* position: absolute; */
    padding-top: 40px;
    bottom: 10px;
}

.card-mission:hover {
    cursor: pointer;
    transition: 0.7s;
    transform: scale(1.03);
}

.back-vac-about {
    position: relative;
    padding: 0;
    width: 50%;
    height: 307px;
    background: radial-gradient(107.25% 875.51% at 100% 85%, #A74AE7 0%, #0085FF 100%)
}

.content-about-vacancy {
    position: absolute;
    top: 15%;
    left: 15%;
}

.btn-about-vacancy {
    color: black;
    margin-top: 10px;
    border: none;
    outline: none;
    width: 250px;
    height: 60px;
    font-weight: 500;
    cursor: pointer;
    background: #FFFFFF;
    border-radius: 40px;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 6px;
}

.btn-about-vacancy:hover {
    background: linear-gradient(-45deg, #0085FF, #A74AE7) border-box;
    border: 2px solid transparent;
    border-radius: 40px;
    color: white;
}

.filials {
    margin: 100px 0px;
}

.german-laction {
    position: absolute;
    top: 12%;
    cursor: pointer;
}

.tajikistan-laction {
    position: absolute;
    top: 25%;
    right: 28%;
    cursor: pointer;
}

.uzbakistan-laction {
    position: absolute;
    top: 20%;
    right: 33%;
    cursor: pointer;
}
.turkey-laction {
    position: absolute;
    top: 22%;
    right: 40%;
    cursor: pointer;
}

.height_mission{
    /* height: 315px !important; */
}

.tooltiptext {
    visibility: hidden;
    width: auto;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 10px;
    position: absolute;
    z-index: 1;
}

.german-laction:hover .tooltiptext {
    visibility: visible;
    top: -20px;
    right: -90px;
}

.uzbakistan-laction:hover .tooltiptext {
    visibility: visible;
    top: -20px;
    right: -90px;
}
.turkey-laction:hover .tooltiptext {
    visibility: visible;
    top: -20px;
    right: -50px;
}

.tajikistan-laction:hover .tooltiptext {
    visibility: visible;
    top: -20px;
    right: -80px;
}


@media (max-width: 500px) {
    .card-mission{
        margin: 0 2%;
    }
    .vacancy-section_1 {
        padding-top: 25px;
        height: 65vh;
        margin-bottom: 10px;
    }
    .card-mission {
        /* height: 220px; */
    }
    .german-laction {
        top: 8%;
    }
    
    .tajikistan-laction {
        top: 20%;
        right: 27%;
    }
    
    .uzbakistan-laction {
        top: 14%;
        right: 32%;
    }
    .turkey-laction {
        top: 17%;
        right: 39%;
    }
}

@media (max-width: 992px) {
    .back-vac-about {
        width: 100%;
    }

    .german-laction {
        width: 40px;
    }

    .tajikistan-laction {
        width: 40px;
    }

    .uzbakistan-laction {
        width: 40px;
    }
    .turkey-laction {
        width: 40px;
    }
}

@media (min-width: 1600px) {
    .content-about-vacancy {
        left: 30%;
    }
}

@media (min-width: 2000px) {
    .content-about-vacancy {
        left: 50%;
    }
}

@media (min-width: 2500px) {
    .content-about-vacancy {
        left: 50%;
    }
}