.main-section {
    height: 99vh;
    background: url(../../assets/images/main/main-back.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.main_slogan {
    font-family: 'Arsenal', sans-serif;
    font-style: normal;
    font-weight: 400;
    color: #FFFFFF;
    /* font-size: 8rem; */
    font-size: 12vmin;
    line-height: 90%;
}

.main_title {
    font-family: 'Arsenal', sans-serif;
    font-weight: 400;
    font-size: 4vmin;
    margin-bottom: 0 !important;
    display: flex;
    justify-content: center;
}

.btn-main-service {
    margin-top: 34px;
    border: none;
    outline: none;
    width: 160px;
    height: 50px;
    font-weight: 500;
    color: #FFFFFF;
    cursor: pointer;
    background: radial-gradient(107.25% 875.51% at 100% 85%, #A74AE7 0%, #0085FF 100%);
    border-radius: 40px;
    font-size: 13px;
    /* line-height: 14px; */
    letter-spacing: 2px;
}

.btn-main-service:hover {
    background: linear-gradient(-45deg, #0085FF, #A74AE7) border-box;
    border: 2px solid transparent;
    border-radius: 40px;
    color: white;
    font-family: "Roboto", sans-serif;
}

.btn-main-call {
    margin-top: 34px;
    border: 2px solid white;
    outline: none;
    width: 150px;
    height: 50px;
    font-weight: 500;
    color: #FFFFFF;
    cursor: pointer;
    border-radius: 40px;
    background: transparent;
    font-size: 13px;
    line-height: 14px;
    letter-spacing: 2px;
}

.btn-main-call:hover {
    border: 2px solid #0085FF;
}

.btn-main-call:hover>span {
    background: radial-gradient(107.25% 875.51% at 100% 85%, #A74AE7 0%, #0085FF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.main-partner-img {
    filter: grayscale(100%) invert(50%);
    cursor: pointer;
}

.main-partner-img:hover {
    filter: grayscale(0);
}

.section-img-partner {
    display: flex;
    align-self: flex-end;
    justify-content: center;
}

.section-main-slogan {
    display: flex;
    flex-flow: column;
    align-self: flex-end;
}

.mh_50 {
    max-height: 50px;
}

.mh_45 {
    max-height: 45px;
}

.mw_150 {
    max-width: 150px;
}

/* button down */
.scrol-down {
    position: absolute;
    display: none;
    justify-content: center;
    align-items: center;
    left: 40%;
    bottom: 0;
    width: 100px;
}

.scrol-down a {
    position: absolute;
    bottom: 0px;
    z-index: 2;
    display: inline-block;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    color: #fff;
    font: normal 400 20px/1 'Josefin Sans', sans-serif;
    letter-spacing: .1em;
    text-decoration: none;
    transition: opacity .3s;
}

.scrol-down a:hover {
    opacity: .5;
}

.scrol-down a {
    padding-top: 50px;
}

.scrol-down a span {
    position: absolute;
    top: 0;
    left: 50%;
    width: 40px;
    height: 40px;
    margin-left: -23px;
    border: 1px solid #fff;
    border-radius: 100%;
    box-sizing: border-box;
}

.scrol-down a span::after {
    position: absolute;
    top: 50%;
    left: 50%;
    content: '';
    width: 16px;
    height: 16px;
    margin: -12px 0 0 -8px;
    border-left: 1px solid #fff;
    border-bottom: 1px solid #fff;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    box-sizing: border-box;
}

.scrol-down a span::before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    content: '';
    width: 40px;
    height: 40px;
    box-shadow: 0 0 0 0 rgba(255, 255, 255, .1);
    border-radius: 100%;
    opacity: 0;
    -webkit-animation: sdb03 3s infinite;
    animation: sdb03 3s infinite;
    box-sizing: border-box;
}

@-webkit-keyframes sdb03 {
    0% {
        opacity: 0;
    }

    30% {
        opacity: 1;
    }

    60% {
        box-shadow: 0 0 0 60px rgba(255, 255, 255, .1);
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}

@keyframes sdb03 {
    0% {
        opacity: 0;
    }

    30% {
        opacity: 1;
    }

    60% {
        box-shadow: 0 0 0 60px rgba(255, 255, 255, .1);
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}

@media (max-width: 700px) {
    .btn-main-service {
        width: 150px;
        height: 50px;
        margin-right: 10px;
    }

    .btn-main-call {
        width: 150px;
        height: 50px;
    }
    .main_slogan {
        /* font-size: 3rem; */
        line-height: 120%;
        margin-top: 0;
    }
    .main_title {
        font-size: 6vmin;
    }
}

@media (max-width: 1000px) {
    .scrol-down {
        display: inherit;
    }
}

@media (max-width: 1300px) {
    .main_slogan {

    }
    .section-img-partner {
        display: none;
    }

    .section-main-slogan {
        align-self: center;
    }
}



 @media (min-width: 2300px) {
    .main_slogan {
        font-size: 8vmin;
    }

    .main_title {
        font-size: 3vmin;
    }
}

/*
@media (min-width: 2500px) {
    .main_slogan {
        font-size: 8vmin;
    }
    .main_title {
        font-size: 2vmin;
    }
} */