.line-max-short-description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -moz-box;
    -moz-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-clamp: 6;
    box-orient: vertical;
}

.project-info {
    overflow: hidden;
    /* height: auto; */
    /* width: auto; */
    position: relative;
}

.project-info .img-project {
    object-fit: cover;
    height: 450px;
}

 .project-description {
    max-height: 60%;
}

 .line-max {
    height: 100%;
    margin: 0;
    line-height: 120%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}


.description-projects {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -moz-box;
    -moz-box-orient: vertical;
    display: -webkit-box;
    /* -webkit-line-clamp: 6; */
    -webkit-box-orient: vertical;
    /* line-clamp: 6; */
    box-orient: vertical;
    height: 60%;
}

.project-info img {
    width: 100%;
    height: 100%;
    transition: 1.5s;
}

.project-info .caption {
    position: absolute;
    top: 150%;
    width: 100%;
    height: 100%;
    background: radial-gradient(107.25% 875.51% at 100% 85%, #A74AE7 0%, #0085FF 100%);
    transition: 0.7s;
    text-align: center;
}

.project-info .caption .content {
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-evenly;
    position: relative;
    color: #fff;
}

.project-info:hover .caption {
    top: 0px;
}

.p-1-local {
    padding: 0.1rem !important;
}

.project-info:hover img {
    transform: scale(1.5);
}

.use-tech {
    display: flex;
    justify-content: center;
}

.section-our-project {
    padding-top: 100px;
}

.wrapper-project {
    margin-top: 50px;
    overflow-x: auto;
    display: flex;
}

.wrapper-project::-webkit-scrollbar {
    display: none;
}

.wrapper-project .item-project {
    width: 450px;
    display: flex;
}

.bnt-left-scroll {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.5);
    /* z-index: 1; */
    left: 0px;
    top: 50%;
    padding: 10px;
    margin-left: 0px;
    cursor: pointer;
}

.bnt-raight-scroll {
    cursor: pointer;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.5);
    padding: 10px;
    right: 0px;
}

.bnt-left-scroll:hover {
    color: white;
    background-color: rgba(45, 85, 255, 0.7);
}

.bnt-raight-scroll:hover {
    color: white;
    background-color: rgba(45, 85, 255, 0.7);
}

.liquid-row-overlay {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute !important;
    top: 0;
    left: 0;
    -webkit-transition: background 0.3s, opacity 0.3s;
    transition: background 0.3s, opacity 0.3s;
}

.bg-fade-dark-06 {
    background-color: rgba(0, 0, 0, 0.6) !important;
}

@media (max-width: 500px) {
    .project-info .img-project {
        height: 450px;
    }

    .section-our-project {
        padding-top: 50px;
    }

    .wrapper-project {
        margin-top: 10px;
    }
}