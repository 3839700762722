
.experiencedTeam{
  margin: 80px 0px;
}

.skill-bars {
  padding: 25px 30px;
}

.skill-bars .bar {
  margin: 40px 0;
}

.skill-bars .bar:first-child {
  margin-top: 0px;
}

.skill-bars .bar .info {
  margin-bottom: 10px;
}

.skill-bars .bar .info span {
  font-weight: 500;
  font-size: 17px;
  opacity: 0;
  animation: showText 0.5s 1s linear forwards;
}

@keyframes showText {
  100% {
    opacity: 1;
  }
}

.skill-bars .bar .progress-line {
  height: 10px;
  width: 100%;
  background: #f0f0f0;
  position: relative;
  transform: scaleX(0);
  transform-origin: left;
  border-radius: 10px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05),
    0 1px rgba(255, 255, 255, 0.8);
  animation: animate 1s cubic-bezier(1, 0, 0.5, 1) forwards;
}

@keyframes animate {
  100% {
    transform: scaleX(1);
  }
}

.bar .progress-line span {
  height: 100%;
  position: absolute;
  border-radius: 10px;
  transform: scaleX(0);
  transform-origin: left;
  /* background: radial-gradient(107.25% 875.51% at 100% 85%, #A74AE7 0%, #0085FF 100%);
  border-radius: 20px;
  animation: animate 1s 1s cubic-bezier(1, 0, 0.5, 1) forwards; */
}
/* .bar .progress-line span[data-scroll="in"] {
   background: radial-gradient(107.25% 875.51% at 100% 85%, #A74AE7 0%, #0085FF 100%);
  border-radius: 20px;
  animation: animate 0.8s 0.8s cubic-bezier(1, 0, 0.5, 1) forwards;
} */
.bar .progress-line span.active {
   background: radial-gradient(107.25% 875.51% at 100% 85%, #A74AE7 0%, #0085FF 100%);
  border-radius: 20px;
  animation: animate 0.6s 0.8s cubic-bezier(1, 0, 0.5, 1) forwards;
}

.bar .progress-line.html span {
  width: 98%;
}

.bar .progress-line.css span {
  width: 92%;
}

.bar .progress-line.jquery span {
  width: 87%;
}

.bar .progress-line.python span {
  width: 50%;
}

.bar .progress-line.mysql span {
  width: 75%;
}

@keyframes showText2 {
  100% {
    opacity: 1;
  }
}

.experiencedTeam .w-sm-75{
  width: 75%;
}
@media (max-width: 500px) {
  .skill-bars {
    padding: 10px 0px;
  }
  .experiencedTeam{
    margin: 30px 0px;
  }
  .experiencedTeam .w-sm-75{
    width: 95%;
  }
}