.respond-from {
    border-radius: 15px;
    transition-duration: 0.4s;
    background: #FFFFFF;
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.1)
}

.respond-from .error-message {
    font-size: 12px;
    color: red;
    position: absolute;
    bottom: 10px;
    left: 8%;
    line-height: 1px;
}

.respond-contact-us {
    border-radius: 15px 10px 0px 0px;
    background: radial-gradient(107.25% 875.51% at 100% 85%, #A74AE7 0%, #0085FF 100%);
    height: 120px;
    padding: 30px;
    margin: 0px -12px 0px -12px;
}

.btn-upload {
    background: #F2F1F1;
    border-radius: 4px;
    border: none;
    padding: 8px;
    font-weight: bold;
}

.respond-from .input-style {
    width: 100%;
}

.respond-from .input-style-div {
    margin: 15px;
    display: flex;
    justify-content: center;
    flex-flow: column;
}
.file-error{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -moz-box;
    -moz-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    line-clamp: 6;
    box-orient: vertical;
}

.tab {
    overflow: hidden;
}

.tab h5 {
    cursor: pointer;
}

.tabcontent {
    display: none;

}

.tablinks.active {
    padding-bottom: 5px;
    border-bottom: 3px solid #2D55FF;
}

.share-link {
    transition: 0.7s;
}

.share-link:hover {
    transform: scale(1.2);
}

.tablinks {
    cursor: pointer;
}

.past-link {
    position: absolute;
    left: 50%;
    transition: 0.7s;
}

@media (max-width: 500px) {
    .respond-contact-us {
        padding: 20px;
    }

    .btn-upload {
        font-size: 14px;
    }
}