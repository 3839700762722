.feedback-section {
  padding-top: 60px;
  padding-bottom: 60px;
}

.privacy-policy {
  padding-left: 5px;
  cursor: pointer;
}

.feedback-from {
  margin: 0px 0px;
  padding: 0;
  border-radius: 15px;
  transition-duration: 0.4s;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
  background: #FFFFFF;
}

.contact-us {
  background: radial-gradient(107.25% 875.51% at 100% 85%, #A74AE7 0%, #0085FF 100%);
  border-radius: 15px 0px 0px 15px;
  height: 445px;
}

.img-feedback {
  height: 400px;
}

.input-style {
  /* padding: 10px 0px 10px 0px; */
  width: 360px;
}

.input-style-div {
  margin: 15px 40px;
}

.error-message {
  font-size: 8px;
  color: red;
}

.section-feedback {
  /* margin-top: 100px; */
}

::-webkit-input-placeholder {
  color: #909090;
  font-weight: 600;
}

.check {
  margin: 30px 0 20px 35px;
}

.check-apply {
  padding: 0;
  margin: 20px 0px 0px 10px;
}

.custom-checkbox>input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

/* для элемента label, связанного с .custom-checkbox */
.custom-checkbox>span {
  display: flex;
  flex-flow: row;
  ;
  align-items: center;
  flex-wrap: wrap;
  /* justify-content: center; */
  user-select: none;
}


/* создание в label псевдоэлемента before со следующими стилями */
.custom-checkbox>span::before {
  content: '';
  display: inline-block;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid #819ef0;
  border-radius: 0.25em;
  margin-right: 0.5em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

/* стили при наведении курсора на checkbox */
.custom-checkbox>input:not(:disabled):not(:checked)+span:hover::before {
  border-color: #819ef0;
}

/* стили для активного чекбокса (при нажатии на него) */
.custom-checkbox>input:not(:disabled):active+span::before {
  background-color: #819ef0;
  border-color: #819ef0;
}

/* стили для чекбокса, находящегося в фокусе */
.custom-checkbox>input:focus+span::before {
  box-shadow: 0 0 0 0.2rem #819ef0;
}

/* стили для чекбокса, находящегося в фокусе и не находящегося в состоянии checked */
.custom-checkbox>input:focus:not(:checked)+span::before {
  border-color: #2D55FF;
}

/* стили для чекбокса, находящегося в состоянии checked */
.custom-checkbox>input:checked+span::before {
  border-color: #2D55FF;
  background-color: #2D55FF;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

/* стили для чекбокса, находящегося в состоянии disabled */
.custom-checkbox>input:disabled+span::before {
  background-color: #819ef0;
}

.social-icon {
  color: white;
}

.social-icon:hover {
  color: #A74AE7
}

.btn-submit {
  border: none;
  outline: none;
  position: relative;
  width: 200px;
  height: 60px;
  font-weight: 500;
  font-size: 18px;
  color: #FFFFFF;
  cursor: pointer;
  background: radial-gradient(107.25% 875.51% at 100% 85%, #A74AE7 0%, #0085FF 100%);
  border-radius: 40px;
  margin: 20px 0px 0px 10px;
  border: none;
  transition: 0.8s;
  text-align: center;
  color: white;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
  border: none;
}

.btn-submit p {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-submit:hover>p>.to-hover {
  display: none;
}

.btn-submit:hover {
  background: linear-gradient(#fff, #fff) padding-box,
    linear-gradient(45deg, #A74AE7, #0085FF) border-box;
  border: 2px solid transparent;
  border-radius: 40px;
  color: black;
}

.btn-submit:hover>p {
  background: radial-gradient(107.25% 875.51% at 100% 85%, #A74AE7 0%, #0085FF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.btn-submit:disabled {
  background: transparent;
  color: #2D55FF;
  border: 1px solid #2D55FF;
}

@media (max-width: 1400px) {
  .btn-submit {
    margin: 15px 0px 0px 0px;
  }

  .contact-us {
    border-radius: 15px 10px 0px 0px;
    height: 160px;
  }
}

@media screen and (max-width:1140px) {
  .feedback-from {
    margin: 0 10px;
  }
}

@media screen and (max-width: 500px) {
  .feedback-from {
    margin: 0 10px;
  }

  .input-style {
    width: 250px;
  }

  .btn-submit {
    width: 150px;
    height: 50px;
    font-size: 16px;
  }

  .custom-checkbox span {
    margin-left: 5px;
    margin-bottom: 12px;
  }

  .custom-checkbox>span::before {
    margin-left: 10px;
  }
}