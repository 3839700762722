.vacancy-section {
    max-height: 65vh;
    background: url(../../assets/images/vacancy/back-vacancy.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.content-vacancy {
    height: 100%;
}

.btn-vacancy {
    margin-top: 10px;
    border: none;
    outline: none;
    width: 300px;
    height: 60px;
    font-weight: 500;
    color: #FFFFFF;
    cursor: pointer;
    background: radial-gradient(107.25% 875.51% at 100% 85%, #A74AE7 0%, #0085FF 100%);
    border-radius: 40px;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 4px;
}

.btn-vacancy:hover {
    background: linear-gradient(-45deg, #0085FF, #A74AE7) border-box;
    border: 2px solid transparent;
    border-radius: 40px;
    color: white;
}

.wrapper-vacancy-card {
    display: flex;
    overflow-x: auto;
}

.wrapper-vacancy-card::-webkit-scrollbar {
    width: 0;
}

.card-vacancy {
    border: 1.5px solid white;
    border-radius: 16px;
    background: transparent;
    width: 400px;
    height: 100%;
    padding: 20px;
    gap: 18px;
    transition: 0.7s;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 10px;
}

.card-vacancy:hover {
    transition: 0.7s;
    transform: scale(1.05);
    background: radial-gradient(93.27% 480.98% at 3.52% 9.03%, rgba(255, 255, 255, 0.24) 0%, rgba(255, 255, 255, 0) 100%);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    font-weight: 700;
}

.card-vacancy:hover p {
    background: radial-gradient(107.25% 875.51% at 100% 85%, #C575FC 0%, #0083FB 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    fill: url(#blue-gradient);
}

.card-vacancy:hover svg {
    fill: url(#blue-gradient);
}

.card-vacancy p,
svg {
    color: #AAAAAA;
}


@media (max-width: 500px) {
    .display-fl-mb {
        display: flex;
        align-items: center;
        text-align: center;
    }

    .content-vacancy {
        height: 65%;
    }

    .jc-ct-mb {
        display: flex;
        justify-content: center;
    }

    .vacancy-section {
        margin-top: 5px;
        margin-bottom: 10px;
        max-height: 85vh;
    }
    .card-vacancy {
        width: 300px;
    }
}