.respond-vacancy-section {
    height: 8vh;
    background: url(../../../assets/images/vacancy/back-vacancy.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 100px;
}

.back-pages {
    cursor: pointer;
    transition: 0.7s;
}

.back-pages:hover {
    transform: scale(1.03);
}

.copy-link {
    background: linear-gradient(250deg, #A74AE7, #0085FF);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    padding: 3px;
    width: 37px;
    height: 37px;
}

.social-link {
    cursor: pointer;
}

.social-link:hover>* {
    transition: 1s;
    transform: scale(1.2);
}

.respond-from .form-select {
    border-radius: 0px;
    border: none;
    border: 0;
    outline: 0;
    border-bottom: 1px solid #909090;
    color: black;
    width: 80%;
    margin: 30px;
}

.respond-from .form-select:focus {
    outline: none !important;
    outline-width: 0 !important;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
}

.btn-respond-vacancy {
    border: none;
    outline: none;
    position: relative;
    width: 90%;
    height: 50px;
    font-weight: 500;
    font-size: 18px;
    color: #FFFFFF;
    cursor: pointer;
    background: radial-gradient(107.25% 875.51% at 100% 85%, #A74AE7 0%, #0085FF 100%);
    border-radius: 40px;
    border: none;
    transition: 0.8s;
    text-align: center;
    color: white;
    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
    border: none;
}

.btn-respond-vacancy p {
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-respond-vacancy:hover>p>.to-hover {
    display: none;
}

.btn-respond-vacancy:hover {
    background: linear-gradient(#fff, #fff) padding-box,
        linear-gradient(45deg, #A74AE7, #0085FF) border-box;
    border: 2px solid transparent;
    border-radius: 40px;
    color: black;
}

.btn-respond-vacancy:hover>p {
    background: radial-gradient(107.25% 875.51% at 100% 85%, #A74AE7 0%, #0085FF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

@media (max-width: 500px) {
    .respond-vacancy-section {
        height: 10vh;
        width: 100%;
        margin-bottom: 50px;
    }
}