.con-address {
  height: 150px;
}

.add-ger {
  margin-top: 52px;
}

.logo-foot {
  margin-top: 17px;
}

.section-contact {
  width: 100%;
  z-index: 10;
  box-sizing: border-box;
  background: #22203E;
}

.section-contact a {
  color: #FFFFFF;
}

.navigate-contact {
  cursor: pointer;
  line-height: 40px;
}

.social-network {
  height: 1000px;
}

.section-contact a.active {
  color: white;
}

.logo-footer {
  height: 70px;
  margin-right: 10px;
}

.p-con-l {
  padding-left: 80px;
}

@media (max-width: 1200px) {
  .con-address {
    height: 180px;
  }
}
@media (max-width: 1000px) {
  .con-address {
    height: 210px;
  }
}
@media (max-width: 700px) {
  .contact-content {
    margin-left: 10px;
    margin-right: 10px;
  }

  .add-ger {
    margin-top: 0px;
  }
}


@media (max-width: 750px) {
  .con-address {
    height: auto;
  }

  .p-con-l {
    padding-left: 0px;
  }
}

@media (max-width: 1400px) {
  .p-con-l {
    padding-left: 0px;
  }
}