.number-speak-section {
    color: #FFFFFF;
    background: #22203E;
    padding-top: 50px;
    padding-bottom: 50px;
}

.verticalLine {
    border-right: 2px solid #F2F1F1;
}

.number-hover {
    cursor: pointer;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
}

.number-hover:hover {
    background: radial-gradient(107.25% 875.51% at 100% 85%, #A74AE7 0%, #0085FF 100%);
    transition: all .3s ease-in-out;
}

@media (max-width:450px) {

    .number-hover {
        padding: 10px;
    }
}